<template>
    <div class="page">
        <div class="banner">
            <div class="container">
                <h2 class="banner-title">{{this.$i18n.t('contact.theme')}}</h2>
            </div>
        </div>
        <div class="container">
            <div class="introduction">
                <p class="introduction-content">
                    {{this.$i18n.t('contact.content')}}
                </p>
                <div class="contact-list">
                    <div class="contact-item">
                        <div class="item-icon qipao"></div>
                        <div class="item-content">
                            <div class="item-content-tip">{{this.$i18n.t('contact.tip1')}}</div>
                            <div class="item-content-button">
                                <span @click="toPage">{{this.$i18n.t('contact.button1')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="contact-item">
                        <div class="item-icon email"></div>
                        <div class="item-content">
                            <div class="item-content-tip">{{this.$i18n.t('contact.tip2')}}</div>
                            <div class="item-content-button">
                                <a href="mailto:support@holowits.com" class="contact">{{this.$i18n.t('contact.button2')}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="contact-item">
                        <div class="item-icon hr"></div>
                        <div class="item-content">
                            <div class="item-content-tip">{{this.$i18n.t('contact.tip3')}}</div>
                            <div class="item-content-button">
                                <a href="mailto:hr@holowits.com" class="contact">{{this.$i18n.t('contact.button3')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactIndex',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },
    created() {

    },

    methods: {
        toPage() {
            this.$router.push({
                path: '/channel'
            })
        },
        sendEmail() {

        }
    },
};
</script>

<style lang="less" scoped>

    .page {
        background-color: #FAFBFC;
        min-height: inherit;
    }
    .banner {
        height: 160px;
        background-image: url(../../assets/image/contact/contact_img.png);
        background-position: center;
        // background-repeat: no-repeat;
        color: #FFFFFF;
        // padding-top: 120px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;


        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.29);
            pointer-events: none;
        }

        .banner-title {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 60px;
            position: relative;
            z-index: 2;
            -webkit-font-smoothing: antialiased;
            text-shadow: 0 0 10px rgba(0, 0, 0, .18);

            .color-active {
                color: #ffc635;
            }
        }
    }
    .introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .introduction-title {
            margin-top: 95px;
            text-align: center;
        }

        .introduction-content {
            font-size: 16px;
            margin-top: 15px;
            text-align: center;
            line-height: 30px;
            .link {
                color: #c7000b;
                cursor: pointer;
            }
        }
    }

    .contact-list {
        margin-top: 30px;
        margin-bottom: 50px;
        .contact-item {
            // width: 600px;
            height: 140px;
            background-color: #FFFFFF;
            border-radius: 8px;
            padding: 30px 15px 30px 15px;
            display: flex;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .item-icon {
                width: 80px;
                height: 80px;
                background-size:100% 100%;
                // flex: 1;
                // float: left;
                &.qipao {
                    background-image: url(../../assets/image/contact/contact_qipao.png);
                }
                &.email {
                    background-image: url(../../assets/image/contact/contact_email.png);
                }
            }

            .item-content {
                // float: left;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .item-content-tip {
                    font-size: 16px;
                }
                .item-content-button {
                    font-size: 16px;
                    color: #C7000B;
                    span {
                        cursor: pointer;
                    }
                    a {
                        color: #C7000B;
                        cursor: default;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        .banner {
            height: 320px;
            background-size:100% 100%;
            .banner-title {
                font-size: 36px;
            }
        }
        .introduction-content {
            font-size: 20px !important;
            margin-top: 30px !important;
            width: 739px;
        }
        .contact-list {
            margin-top: 30px;
            margin-bottom: 50px;
            .contact-item {
                width: 600px;
                height: 140px;
                background-color: #FFFFFF;
                border-radius: 8px;
                padding: 30px 0px 30px 20px;
                display: flex;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                .item-icon {
                    width: 80px;
                    height: 80px;
                    background-size:100% 100%;
                    &.qipao {
                        background-image: url(../../assets/image/contact/contact_qipao.png);
                    }
                    &.email {
                        background-image: url(../../assets/image/contact/contact_email.png);
                    }
                    &.hr {
                        background-image: url(../../assets/image/contact/hr.png);
                    }
                }

                .item-content {
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .item-content-tip {
                        font-size: 16px;
                    }
                    .item-content-button {
                        font-size: 16px;
                        color: #C7000B;
                        span {
                            cursor: pointer;
                        }
                        a {
                            color: #C7000B;
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
</style>